.contenedor-principal {
    width: 100%;
    height: 80px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    align-items: center;
    background: #7c272e;
    text-align: center;
}

.menu-movil {
    display: none;
}

.contenedor-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 5px;
}

.contenedor-logo a {
    text-decoration: none;
}

.contenedor-logo h1 {
    color: white;
    font-size: 30px;
}

.contenedor-logo h1:hover {
    color: rgb(255, 133, 133);
    cursor: pointer;
}

.contenedor-menu a {
    width: 100%;
}

.contenedor-menu a {
    text-decoration: none;
    color: white;
    margin-left: 20px;
    margin-right: 10px;
    font-size: 16.5px;
    font-weight: normal;
    letter-spacing: 0.5px;
    padding-bottom: 7px;
}

.contenedor-menu a:hover {
    color: rgb(255, 133, 133);
}

.menu-seleccionado {
    border-bottom: 3px solid rgb(177, 41, 41);
}

.contenedor-nav-redes-sociales {}

.contenedor-nav-redes-sociales i {
    color: rgba(255, 255, 255, 0.603);
    margin-left: 10px;
    margin-right: 10px;
    font-size: 25px;
}

.contenedor-nav-redes-sociales i:hover {
    color: rgb(255, 255, 255);
}

.contenedor-menu-movil {
    display: none;
}

@media (max-width:801px) {
    .contenedor-logo {
        padding-left: 10px;
    }
}

@media (max-width: 800px) {
    .contenedor-principal {
        justify-content: space-between;
        padding-left: 0px;
        padding-right: 20px;
        z-index: 10;
        position: fixed;
    }
    .contenedor-principal {
        position: fixed;
        top: 0;
    }
    .contenedor-menu {
        display: none;
    }
    .contenedor-nav-redes-sociales {
        display: none;
    }
    .menu-movil {
        display: flex;
    }
    .menu-movil i {
        color: white;
        font-size: 40px;
    }
    .contenedor-menu-movil {
        background: #7c272e;
        width: 100%;
        display: flex;
    }
    .contenedor-menu-movil nav {
        z-index: 3;
        background: #7c272e;
        display: flex;
        position: fixed;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        height: auto;
        overflow: hidden;
        padding-bottom: 15px;
    }
    .contenedor-menu-movil nav {
        position: fixed;
        transform: translate3d(0, 0, 0);
    }
    .contenedor-menu-movil a {
        text-decoration: none;
        color: white;
        font-size: 20px;
        margin-top: 20px;
        letter-spacing: 0.5px;
        text-align: center;
    }
    .contenedor-menu-movil a:hover {
        color: rgb(255, 133, 133);
    }
    .contenedor-redes-sociales-movil {
        display:flex;
        flex-flow: row nowrap;
        justify-content:center;
        align-items: center;
        margin-top: 0px;
        overflow: hidden;
    }
    .contenedor-redes-sociales-movil a {
        display:flex;
        flex-flow: row nowrap;
        justify-content:center;
        align-items: center;
        text-align: center;
        background: black;
        padding: 15px;
        border-radius:100px;
        overflow: hidden;
        margin-left:3px;
        margin-right:3px;
    }

    .contenedor-redes-sociales-movil i {
        overflow:hidden;
       font-size: 16px;
       
       margin:0px;
       padding: 0px;
       
    }



}