.contendor-principal-asidebar{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    background:#2f353a;

}
    .contenedor-asidebar-logo{
        width: 100%;
        height: 60px;
        display:flex;
        flex-flow: row nowrap;
        justify-content:center;
        align-items:flex-start;
        text-align: center;
        background:white;
        overflow: hidden;
    }

    .contenedor-asidebar-logo img{
        padding:10px;
        width: 130px;
        
    }

    .contenedor-asidebar-titulo-menu{
        display: none;
    }

    .contenedor-asidebar-menu{
        width: 100%;
        display: flex;
        flex-flow: column nowrap;
        justify-content:center;
        align-items: flex-start;
        margin-top:30px;
    }

    .contenedor-asidebar-menu-items{
        width: 100%;
        height: 45px;
        display: flex;
        flex-flow:row nowrap;
        padding-left: 15px;
        justify-content:flex-start;
        align-items: center;
        margin-top:10px;
    }

    .contenedor-asidebar-menu-items i{
        color:rgb(226, 214, 214);
    }

    .contenedor-asidebar-menu-items h1{
        color:white;
        font-family: 'Quicksand', sans-serif;
        font-weight: normal;
        font-size: 15px;
        margin-left:15px;
        letter-spacing:2px;
    }

    .contenedor-asidebar-menu a:hover{
       background:#7c272e;
       cursor: pointer;
    }

    .asidebar-activo{
        background:#7c272e;
    }


@media (max-width:950px) {
    .contenedor-asidebar-logo{
        display: none;
    }

    .contenedor-asidebar-titulo-menu{
        width: 100%;
        padding:10px;
        background:#3a3f44;
        display: flex;
        justify-content: center;
        align-items: center;
    
    }

    .contenedor-asidebar-titulo-menu h1{
       
        color:white;
        text-align: center;
        font-size: 25px;
        font-weight: normal;
    }
    
    .contenedor-asidebar-menu{
        margin-top:0px;
    }
}



@media (max-width:400px){
    .contenedor-asidebar-titulo-menu h1{
        
        font-size: 20px;

    }
}


