.fondo-login{
    display:flex;
    flex-flow: column nowrap;
    background:#ecf0f5;
    width: 100%;
}
.content-login{
    display:flex;
    flex-flow: column nowrap;
    width: 35%;
    margin-left:32.5%;
    margin-right:32.5%;
    justify-content:center;
    align-items: center;
    margin-top:20px;
    margin-bottom:50px;
    padding-top:20px;
    border-radius: 15px;
    background:rgb(255, 255, 255);
    box-shadow: 6px 7px 7px rgba(0, 0, 0, 0.199);
}

    .content-login h1{
        margin-bottom: 40px;
    }

    .content-login img{
        width: 150px;
        margin-bottom: 20px;
        border-radius: 50%;
    }


.content-login form{
    display:flex;
    flex-flow: column nowrap;
    width: 100%;
    justify-content:center;
    align-items: center;
}
    .content-login #label{
        margin-bottom: 5px;
        font-size:25px;
    }

    ::placeholder{
        text-align: center;
        color:rgba(0, 0, 0, 0.438);
    }

    :focus::-webkit-input-placeholder {
        opacity: 0;
        transform: translate(70%);
        -webkit-transition: all 0.65s ease-in-out;
        transition: all 0.90s ease-in-out;
      }


    .content-login #email{
        width: 350px;
        margin-bottom: 20px;
        border:none;
        border-radius: 5px;
        background:rgb(235, 235, 235);
        padding:11px;
        font-size:19px;
        outline: none;
    }

    .content-login #email:focus{
        box-shadow: 0 0 5px #7c272e; 
        outline: none;
        border: 1px solid #7c272e; 
    }
    

    .content-login #password{
        width: 350px;
        margin-bottom: 40px;
        border:none;
        border-radius: 5px;
        background:rgb(235, 235, 235);
        padding:11px;
        font-size:19px;
        outline: none;
    }

    .content-login #password:focus{
        box-shadow: 0 0 5px #7c272e; 
        outline: none;
        border: 1px solid #7c272e; 
    }

    .content-login #money{
        width: 350px;
        margin-bottom: 20px;
        border:none;
        border-radius: 5px;
        background:rgb(235, 235, 235);
        padding:11px;
        font-size:19px;
        outline: none;
    }


    .content-login #send{
        width: 350px;
        margin-bottom: 15px;
        border:none;
        border-radius: 5px;
        background:#7c272e; 
        padding:8px;
        font-size:26px;
        color:white;
        outline: none;
    }

    

    #cargando{
        width:50px;
    }

    .content-login #send:hover{
        background:#a7353f; 
        color:#dadada;
        cursor: pointer;
    }

     a{
        text-decoration: none;
    }

    .content-login #reset-password{
        width: 350px;
        margin-bottom: 50px;
        text-align: right;
        padding:5px;
        font-size:19px;
        color:#7c272e; 
    }

    .content-login #reset-password:hover{
        color:#d84450; 
    }



    





@media (max-width:1050px){
    .content-login{
      
        width: 40%;
        margin-left:30%;
        margin-right:30%;
        
    }
}

@media (max-width:950px){
    .content-login{
      
        width: 45%;
        margin-left:27.5%;
        margin-right:27.5%;
        
    }
}

@media (max-width:828px){
    .content-login{
      
        width: 50%;
        margin-left:25%;
        margin-right:25%;
        
    }
}

@media (max-width:732px){
    .content-login{
      
        width: 55%;
        margin-left:22.5%;
        margin-right:22.5%;
        
    }
}

@media (max-width:677px){
    .content-login{
      
        width: 60%;
        margin-left:20%;
        margin-right:20%;
        
    }
}

@media (max-width:612px){
    .content-login{
      
        width: 65%;
        margin-left:17.5%;
        margin-right:17.5%;
        
    }
}

@media (max-width:559px){
    .content-login{
      
        width: 70%;
        margin-left:15%;
        margin-right:15%;
        
    }
}

@media (max-width:520px){
    .content-login{
        
        width: 75%;
        margin-left:12.5%;
        margin-right:12.5%;
        
    }
}

@media (max-width:485px){
    .content-login{
        
        width: 80%;
        margin-left:10%;
        margin-right:10%;
        
    }
}

@media (max-width:456px){
    .content-login{
        
        width: 85%;
        margin-left:7.5%;
        margin-right:7.5%;
        
    }
}

@media (max-width:432px){
    .content-login{
        
        padding-top:25px;
        width: 90%;
        margin-left:5%;
        margin-right:5%;
        
    }
}

@media (max-width:405px){
    .content-login{
        
        width: 95%;
        margin-left:2.5%;
        margin-right:2.5;
        
    }
}

@media (max-width:380px){
    .content-login{
      
        width: 100%;
        margin-left:0%;
        margin-right:0%;
        
    }
}