@media(max-width:1344px){
    #botones-tabla{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        flex-flow: column wrap;
    }
    
    #editar{
        margin-right: 0px;
        margin-bottom: 5px;
    }
    
    #remover{
        margin-left: 0px;
    }
}

@media (max-width:900px){
    #botones-tabla{
    
        flex-flow: row nowrap;
    }

    #editar{
        margin-right: 5px;
        margin-bottom: 0px;
        width: 30%;
    }

    #remover{
        margin-left: 5px;
        width: 30%;
    }
}



@media (max-width:450px){
   

    #editar{
        width: 50%;
    }

    #remover{
        width: 50%;
    }
}