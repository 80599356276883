.contenedor-botton-contacto-movil{
    display:none;
}

.contenedor-botton-contacto{
    width: 100%;
    display:flex;
    justify-content: flex-end;
    align-items: center;
    position: fixed;
    bottom: 0;
}


.forma-botton-contacto {
    display: flex;
    flex-flow: row nowrap;
    justify-content:center;
    align-items: center;
    margin-right: 20px;
    background:#7c272eee;
    height: 55px;
    width: 280px;
    font-size: 16.5px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    overflow: hidden;
}

.forma-botton-contacto:hover {
    background:#a73841ee;
    cursor: pointer;
}

.botton-contactanos{
    display: flex;
    flex-flow: row nowrap;
    justify-content:center;
    align-items: center;
    width: 20%;
    overflow: hidden;
}

.botton-contactanos i{
    color:white;
    font-size:36px;
    margin-left: 15px;
}

.botton-contactanos-info{
    display: flex;
    flex-flow: column nowrap;
    justify-content:center;
    align-items: center;
    text-align: center;
    width: 100%;
    overflow: hidden;
    color:White;
}

.botton-contactanos-info h2{
    font-size:19px;
}



.botton-contactanos-info p{
    font-size:11px;
    font-weight: normal;
    font-family: arial;
}


@media(max-width:500px){
    .contenedor-botton-contacto{
        display:none;
    }

    .contenedor-botton-contacto-movil{
        display: flex;
    }

    .contenedor-botton-contacto-movil{
        width: 100%;
        display:flex;
        justify-content: flex-end;
        align-items: center;
        position: fixed;
        bottom: 20px;
    }
    
    .forma-botton-contacto-movil {
        display: flex;
        flex-flow: row nowrap;
        justify-content:center;
        align-items: center;
        margin-right: 35px;
        background:#7c272eee;
        height: 65px;
        width: 65px;
        
        font-size: 16.5px;
        border-radius: 50%;
        overflow: hidden;
    }
    
    .forma-botton-contacto-movil:hover {
        background:#a73841ee;
        cursor: pointer;
    }
    
    .botton-contactanos-movil{
        display: flex;
        flex-flow: row nowrap;
        justify-content:center;
        align-items: center;
        width: 100%;
        overflow: hidden;
    }
    
    .botton-contactanos-movil i{
        color:white;
        font-size:36px;
    }
}