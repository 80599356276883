.contenedor-principal-dashboard{
   
}



@media (max-width:900px){

    .contenedor-cargos-agregar{
        justify-content:center;
        padding-left:0px;
    }

    .contenedor-tabla-cargos .table {
        width:90%;
    }
    .contenedor-tabla-cargos #id_usuario {
        display: none;
        
    }
    .contenedor-tabla-cargos .thead {
        display: none;
        
    }
    .contenedor-tabla-cargos #nom_usuario {
        background: #f0f0f0;
        font-weight:bold;
        font-size:1.3em;
    }
    .contenedor-tabla-cargos tbody td {
        display: block;
        text-align:center;
    }
    .contenedor-tabla-cargos tbody td:before {
        content: attr(data-th);
        display: block;
        text-align:center;
    }

    #id_usuario #edit{
        background:#f39c12;
        padding:6px;
        font-size: 18px;
        border:none;
        width: 150px;
        height: 36px;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }
    

    #id_usuario #remove{
        background:#dd4b39;
        padding:6px;
        font-size: 18px;
        width: 150px;
        height: 36px;
        border:none;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }

}