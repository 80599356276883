.contenedor-proyectos-titulo{
    width: 100%;    
    height: 210px;
    position:relative;
    display: inline-block;
    overflow: hidden;
}

.contenedor-proyectos-titulo img{
    width: 100%;    
    height: 100%;  
    position:relative;
    display: inline-block;
    overflow: hidden;
}

.proyectos-titulo{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.proyectos-titulo h1{
   color: white;
   font-size: 40px;
    text-transform: uppercase;
}

.proyectos-titulo h3{
    color: white;
    font-size: 17px;
     text-transform: uppercase;
 }

.contenedor-proyectos-info{
    display: flex;
    justify-content:center;
    align-items: center;
    width: 100%;
    height: 120px;
    margin-top:-5px;
    background:#7c272e;
    overflow: hidden;
}

.contenedor-proyectos-info p{
    margin-left:20%;
    margin-right:20%;
     color:white;
     text-align: justify;
}

.contenedor-proyectos-items{
    margin-top:50px;
    padding-left:20%;
    padding-right:20%;
    width: 100%;
    margin-bottom:40px;
}

.contenedor-proyectos-items p{
    font-size: 17px;
   margin-bottom: 20px;
}

.contenedor-proyectos-items li{
    list-style-type: square;
    margin-left:15px;
}

.contenedor-proyectos-imagenes{
    width: 100%;
    height:100%;
    display:flex;
    flex-flow: row nowrap;
    justify-content:space-around;
    align-items: center;
    margin-top:20px;
    margin-bottom:30px;
    overflow:hidden;
}

.contenedor-proyectos-imagenes img{
    width: 30%;
}

@media (max-width:800px){
    .contenedor-proyectos-titulo{
        margin-top:80px;
        height: 130px;
    }
}


@media (max-width:780px){
    .proyectos-titulo{
        position: absolute;
        top: 32%;
        left: 20%;
        transform: translate(-20%, -20%);
        
    }

    .proyectos-titulo h1{
        font-size: 25px;
     }

     .proyectos-titulo h3{
        font-size: 15px;
     }


}

@media (max-width:650px){
    .contenedor-proyectos-imagenes{
        flex-flow: column nowrap;
        height: auto;
    }

    .contenedor-proyectos-imagenes img{
       width: 100%;
       margin-bottom: 25px;
    }
}

@media (max-width:520px){
    .contenedor-proyectos-info p{
        margin-left:7%;
        margin-right:7%;
        font-size: 17px;
        text-align: center;
    }

    .contenedor-proyectos-items{
        padding-left:6%;
        padding-right:6%;
    }

    .contenedor-proyectos-items li{
        margin-bottom: 10px;
    }
}
