.Modal-active{
    visibility: hidden;
    transition: all 500ms ease;
    transform:translateY(-70%);
    display: none;
    margin: 0;
    padding: 0;
   
}

.Modal-desactive{
    z-index: 4;
    display:flex;
    flex-flow: column nowrap;
    justify-content:center;
    align-items:center;
    width: 100%;
    height: 100vh;
    transform:translateY(0%);
    animation-name: animatemodal;
    transition: all 500ms ease;
    position: absolute;
}

.contend-modalU{
    overflow:auto;
    display:flex;
    flex-flow: column nowrap;
    justify-content:flex-start;
    align-items:center;
    width: 100%;
    height:100vh;
    background:rgba(0, 0, 0, 0.5);
    margin: 0;
    padding: 0;
    padding-top:20px;
    
}


.ModalHeaderU{
    background:#2f353a;
    z-index: 40;
    width: 45%;
    padding: 15px;
    display:flex;
    flex-flow:row nowrap;
    justify-content: space-between;
    align-items: center;
}

.ModalHeaderU > h2{
   color:white;
   font-size: 20px;
}

.ModalHeaderU > i{
    color:white;
    font-size: 20px;
    cursor: pointer;
 }

.ModalHeaderU i:hover{
    color:rgb(92, 92, 92);
 }

#icon-close{
    margin-left: 20px;
}

.ModalBodyU{
    width: 45%;
    background:white;
    display:flex;
    flex-flow:column nowrap;
    justify-content:center;
    align-items:center;
    border-left:0.5px solid black;
    border-right:0.5px solid black;
    margin-bottom: 50px;
}

.FormGroupU{
    width: 100%;
    display:flex;
    flex-flow:column nowrap;
    justify-content:center;
    align-items:flex-start;
    padding:20px;
    
}

.FormGroupU label{
    font-size: 20px;
}

.FormGroupU input{
    border:1px solid rgba(0, 0, 0, 0.199);
    font-size: 20px;
    padding:8px;
    width: 99%;
    height: 37px;
    border-radius: 3px;
    margin-top:6px;
    margin-bottom:6px;
  
}

.FormGroupU input:focus{
    outline: 0 none; 
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 4px #2f353a;; 
}


.FormGroupU #id_cargo{
    border:1px solid rgba(0, 0, 0, 0.199);
    padding:8px;
    width: 99%;
    height: 40px;
    color:black;
    font-size: 17px;
    text-align-last: center;
    border-radius: 3px;
    margin-top:6px;
    margin-bottom:6px;
}


.FormGroupU #id_cargo:focus{
    outline: 0 none; 
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 4px #2f353a;; 
}

.FormGroupU #id_cargo_usuario{
    border:1px solid rgba(0, 0, 0, 0.199);
    padding:8px;
    width: 99%;
    height: 37px;
    color:black;
    font-size: 17px;
    text-align-last: center;
    border-radius: 3px;
    margin-top:6px;
    margin-bottom:6px;
}


.FormGroupU #id_cargo_usuario:focus{
    outline: 0 none; 
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 4px rgb(95, 9, 255); 
}

.FormGroupU #id_permiso_usuario{
    border:1px solid rgba(0, 0, 0, 0.199);
    font-size: 20px;
    padding:8px;
    width: 99%;
    height: 37px;
    color:black;
    font-size: 17px;
    text-align-last: center;
    border-radius: 3px;
    margin-top:6px;
    margin-bottom:6px;
}

.FormGroupU #id_permiso_usuario:focus{
    outline: 0 none; 
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 4px rgb(95, 9, 255); 
}

.ModalFooterU{
    border:0.5px solid black;
    width: 40%;
    background:white;
    padding: 15px;
    display:flex;
    flex-flow:row nowrap;
    justify-content:center;
    align-items:center;
}

#yes{
    width: 100%;
    height: 40px;
    font-size: 20px;
    background:#0071B8;
    color:white;
    border-radius:5px;
    border:none;
}

#cancel{
    width: 100%;
    height: 40px;
    font-size: 20px;
    background:#dd4b39;
    color:white;
    border-radius:5px;
    border:none;
}

#yes:hover{
    background:rgb(122, 109, 238);
    cursor: pointer;
}

#cancel:hover{
    background:#ff6c59;
    cursor: pointer;
}

@keyframes animatemodal{
    from {top:-300px; opacity: 0;}
    to{top:0px; opacity: 1;}
}

@media (max-width:780px){
    .ModalHeaderU{
        width: 60%;
       
    }
    .ModalBodyU{
        width: 60%;
       
    }

}

@media (max-width:500px){
    .ModalHeaderU{
        width: 90%;
       
    }
    .ModalBodyU{
        width: 90%;
       
    }

}