.contenido-quienes-somos {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    margin-top: 70px;
    margin-bottom: 30px;
}

.contenido-imagenes-quienes-somos {
    width: 590px;
    display: flex;
    flex-flow: row nowrap;
    overflow: hidden;
}

.contenido-imagenes-quienes-somos img {
    width: 100%;
    min-width: 200px;
    min-height: 150px;
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
}

.contenido-detalle-quienes-somos {
    width: 50%;
    display: flex;
    flex-flow: column nowrap;
    overflow: hidden;
}

.contenido-detalle-quienes-somos-contenido{
    padding-right: 35px;
}

.btn-whatsapp {
    padding: 12px;
    margin-top: 15px;
    background: #7c272e;
    width: 250px;
    border-radius: 50px;
    text-align: center;
}

.btn-whatsapp i {
    font-size: 25px;
    color: white;
}

.btn-whatsapp label {
    font-family: Arial;
    color: white;
    margin-left: 5px;
    font-size: 18px;
}

.btn-whatsapp:hover {
    cursor: pointer;
    background: #a02832;
}

.btn-whatsapp a:hover {
    cursor: pointer;
}

.btn-whatsapp label:hover {
    cursor: pointer;
}

.contenido-detalle-quienes-somos h2 {
    margin-bottom: 15px;
    font-size: 30px;
    color:#7c272e;
}

.contenido-detalle-quienes-somos p {
    font-size: 18px;

}


.contenedor-quienes-somos-garantia{
    width: 100%;
    height:100px;
    display:flex;
    justify-content:center;
    align-items: center;
    background: #7c272e;
    border:1px solid rgba(0, 0, 0, 0.596);
}

.contenedor-quienes-somos-garantia p{
   text-align:center;
   font-size: 25px;
   color:white;
}


.contenido-mision-vision {
    width: 100%;
    display: flex;
    flex-flow:column nowrap;
    justify-content:center;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 40px;
    padding-top:20px;
    padding-bottom:20px;
    overflow: hidden;
    
}

.contenido-mision {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    text-align: center;
    background:#fbfdff;
    padding: 40px;
    height: 470px;
    overflow: hidden;
    box-shadow: 0px 0px 7px 2px rgba(0, 0, 0, 0.199);
   
}

.contenido-mision h2 {
    font-size: 30px;
    color:#7c272e;
}

.contenido-mision p {
    margin-top: 30px;
    color: black;
    font-size: 18px;
}

.contenido-mision img {
    margin-top:13px;
    width: 78%;
    border-radius: 3px;
    
}



.contenido-vision {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    text-align: center;
    background:#fbfdff;
    padding: 40px;
    height: 470px;
    box-shadow: 0px 0px 7px 2px rgba(0, 0, 0, 0.199);
    
}

.contenido-mision-datos{
    width: 50%;
    
}

.contenido-mision-imagen{
    width: 50%;
    overflow: hidden;
    border-radius: 3px;
}

.contenido-vision h2 {
    font-size: 30px;
    color:#7c272e;
}

.contenido-vision-datos{
    width: 50%;
}

.contenido-vision-imagen{
    width: 50%;
    overflow: hidden;
    border-radius: 3px;
}
.contenido-vision p {
    margin-top: 30px;
    font-size: 18px;
}

.contenido-vision img {
    margin-top: 20px;
    width: 80%;
    border-radius: 3px;
}


.contenedor-valores{
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom:30px;
    overflow: hidden;
}

.contenedor-valores-titulo h2{
    margin-top:0px;
    margin-bottom:15px;
    font-size: 30px;
    color:#7c272e;
}

.fondo-contenedor-valores{
    justify-content:space-around;
    background:#fbfdff;
    width: 83.7%;
    padding: 40px;
    height: 250px;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0px 0px 12px 4px rgba(0, 0, 0, 0.199);
}

.contenedor-valores-articulos{
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    justify-content: center;
    align-items:center;
    margin-bottom: 20px;
  
}

.fondo-contenedor-valores img{
    width: 100px;
    
}


#img-valores{
    padding-bottom: 300px;
}

.contenedor-valores-articulos h3{
    margin-left:25px;
    font-weight: normal;
}

.contenedor-servicios-titulo{
    margin-top:30px;
    margin-left: 5%;
    margin-right: 5%;
    width: 90%;
    display: flex;
    flex-flow: column nowrap;
    justify-content:center;
    align-items: center;
    margin-bottom:15px;
}

.contenedor-servicios-titulo h2{
    margin-bottom:15px;
    font-size: 27px;
    color:#7c272e;
}

.contenedor-servicios-titulo p{
    font-size:17px;
    text-align:justify;
    margin-bottom: 15px;
}

.contenedor-todos-servicios{
    width: 100%;
    display:flex;
    flex-flow:row nowrap;
    justify-content:space-around;
    align-items: center;
}

.contenedor-servicios-items{
    width: 30%;
    display: flex;
    flex-flow: column nowrap;
    justify-content:center;
    align-items: center;
    margin-left: 15px;
    margin-right: 15px;
    overflow: hidden;
    border-radius:3px;
}

.contenedor-servicios-items a{
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content:center;
    align-items: center;
    margin-left: 15px;
    margin-right: 15px;
    overflow: hidden;
    color:black;
}

.contenedor-servicios-items img{
   width: 100%;
   border-radius:3px;
}

.contenedor-servicios-items img:hover{
    -webkit-transform: scale(1.11, 1.11);
    -webkit-transition-timing-funcion: ease-out;
    -webkit-transition-duration: 250ms;
}

.contenedor-servicios-items h3{
    margin-top: 15px;
    font-size:19px;
}

.contenedor-servicios-items h4{
    font-weight: normal;
    font-size:15px;
    color:rgba(0, 0, 0, 0.527);
}

.contenedor-servicios-items h4:hover{
    cursor: pointer;
    color:black;
    border-bottom: 1px solid black;
}


.contenido-como-diferenciamos-principal{
    width:100%;
    display: flex;
    flex-flow: row wrap;
    justify-content:center;
    align-items: center;
    margin-bottom: 130px;
    overflow: hidden;
}

.contenido-como-diferenciamos-titulo{
    margin-left: 5%;
    margin-right: 5%;
    width: 90%;
    display: flex;
    flex-flow: column wrap;
    justify-content:center;
    align-items: center;
    margin-bottom: 45px;
}

.contenido-como-diferenciamos-titulo h2{
    font-size: 30px;
    color:#7c272e;
    text-align: center;
}

.contenido-como-diferenciamos-titulo p{
    margin-top:20px;
    font-size: 17px;
}

.contenedor-como-diferenciamos-items{
    width: 96%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-items: flex-start;
    margin-top: 10px;
    margin-left: 4%;
    margin-right:4%;
}

.contenido-como-diferenciamos-item-izquierda{
    width: 31%;
    height:auto;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 50px;
    padding-bottom: 30px;
    overflow: hidden;

}

.contenido-como-diferenciamos-item-izquierda img{
    width: 100%;
    border-radius: 3px;
}

.contenido-como-diferenciamos-item-izquierda h3{
    margin-top:30px;
    text-align: center;
}

.contenido-como-diferenciamos-item-izquierda p{
    width: 93%;
    margin-top:30px;
    text-align: justify;
}

.contenido-como-diferenciamos-item-derecha{
    width: 45%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    margin-bottom: -200px;
    overflow: hidden;
    padding-bottom: 50px;
}

.contenido-como-diferenciamos-item-derecha img{
    width: 90%;
    border-radius: 3px;
}

.contenido-como-diferenciamos-item-derecha h3{
    margin-top:30px;
    text-align: center;
}

.contenido-como-diferenciamos-item-derecha p{
    width: 93%;
    margin-top:30px;
    text-align: justify;
}


@media (max-width:1240px){
    .contenido-detalle-quienes-somos-contenido{
        margin-left: 40px;
    }
}

@media (max-width:880px) {
   
    .contenido-detalle-quienes-somos h2 {
        font-size: 25px;
    }
    .contenido-detalle-quienes-somos p {
        font-size: 15px;
    }
    .fondo-contenedor-valores{
        width: 78%;
    }

    .contenido-como-diferenciamos-titulo h2{
        font-size: 25px;
    }
}


@media (max-width:890px) {
    .contenido-mision p {
        font-size: 16px;
    }
    
    .contenido-vision p {
        font-size: 16px;
    }
    
}

@media (max-width:762px) {
    .contenido-quienes-somos {
        width: 100%;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
        margin-top: 40px;
    }
    .contenido-quienes-somos h2 {
        text-align: center;
    }
    .contenido-quienes-somos p {
        text-align: justify;
    }
    .contenido-imagenes-quienes-somos {
        width: 600px;
        justify-content: center;
        margin: 0px;
    }
    .btn-whatsapp{
        width: 100%;
    }
    .contenido-imagenes-quienes-somos img {
        width: 100%;
        margin-bottom: 20px;
    }
    .contenido-detalle-quienes-somos {
        width: 100%;
        margin: 0px;
    }

    .contenedor-quienes-somos-garantia p{
        font-size: 19px;
     }

    .contenido-mision-vision {
        width: 100%;
        flex-flow: column nowrap;
        margin-top: -20px;
        
    }
    .contenido-mision {
        width: 100%;
        margin: 0px;
        margin-bottom: 6px;
       flex-flow: column nowrap;
    }

    .contenido-mision-datos{
        width: 96%;
    }
    
    
    .contenido-mision-imagen{
        width: 128%;
  
    }

    .contenido-mision-imagen img{
        width: 100%;
    }

    .contenido-mision p {
        text-align: justify;
    }
    .contenido-vision {
        margin: 0px;
        flex-flow: column-reverse nowrap; 
    }

    .contenido-vision-datos{
        width: 96%;
    }

    .contenido-vision-imagen{
        width: 128%;
    }

    .contenido-vision-imagen img{
        width: 100%;
    }

    .contenido-vision p {
        text-align: justify;
      
    }

    .contenedor-valores-articulos{
        flex-flow: column nowrap;
        
    }

    .contenedor-valores-articulos h3{
        margin-left:0px;
        
    }

    .fondo-contenedor-valores{
        width: 400px;
    }

    .contenedor-todos-servicios{
        margin-bottom: 20px;
    }

    .contenido-como-diferenciamos-item-izquierda{
        width: 100%;
        margin-bottom: 0px;
    }

    .contenido-como-diferenciamos-item-derecha{
        width: 100%;
        margin-bottom: 0px;
    }

    .contenedor-como-diferenciamos-items{
        flex-flow: column nowrap;
    }

    .contenido-como-diferenciamos-item-izquierda{
        margin-bottom: 40px;
    }
}



@media (max-width:604px) {
    .contenido-imagenes-quienes-somos {
        width: 500px;
    }

    .contenido-como-diferenciamos-titulo p{
        margin-top:20px;
        font-size: 14px;
    }
}


@media (max-width:500px){
    

    .contenido-quienes-somos{
        width:100%;
        overflow:hidden;
        margin-top:10px;
    }

    .contenido-como-diferenciamos-item-izquierda p{
        width: 86%;
        margin-top:30px;
        text-align: justify;
    }

    .contenido-como-diferenciamos-item-derecha p{
        width: 86%;
        margin-top:30px;
        text-align: justify;
    }

    @media (max-width:650px){
        .contenedor-todos-servicios{
            flex-flow: column nowrap;
        }
    
        .contenedor-servicios-items{
            width: 100%;
            margin-bottom: 20px;
        }

        .contenedor-servicios-items h3{
            font-size:22px;
        }

        .contenedor-servicios-items h4{
            font-size:19px;
        }
    }
}

@media (max-width:600px){
    .contenido-como-diferenciamos-titulo{
        width: 100%;
    }

    .contenido-como-diferenciamos-titulo p{
        text-align: center;
    }
}

@media (max-width:457px){
    .contenido-imagenes-quienes-somos {
        width: 100%;
        justify-content:center;
        align-items: center;
        
    }

    .contenido-imagenes-quienes-somos img {
        width: 100%;
    }

    
}

@media (max-width:420px){
    .contenido-quienes-somos{
        margin-top:-10px;
    }

    .contenido-imagenes-quienes-somos{
        margin-top:-50px;
    }
}


@media (max-width:370px){
    .contenido-quienes-somos{
        margin-top:-35px;
    }

    .btn-whatsapp label{
        font-size: 15px;
    }

    .btn-whatsapp i{
        font-size: 19px;
    }
}

@media (max-width:350px){
   .contenido-mision p{
       padding-left:20px;
       padding-right:20px;
   }

   .contenido-vision p{
    padding-left:20px;
    padding-right:20px;
}
}

@media (max-width:315px){
    .contenido-quienes-somos{
        margin-top:-50px;
    }
}
