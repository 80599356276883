.fondo-forgot-password{
    display:flex;
    flex-flow: column nowrap;
    background:rgb(226, 226, 226);
    width: 100%;
}

.content-forgot-password{
    display:flex;
    flex-flow: column nowrap;
    width: 35%;
    margin-left:32.5%;
    margin-right:32.5%;
    justify-content:center;
    align-items: center;
    margin-top:20px;
    margin-bottom:50px;
    padding-top:0px;
    border-radius: 15px;
    background:rgb(255, 255, 255);   
    box-shadow: 6px 7px 7px rgba(0, 0, 0, 0.199);
   
}


.content-forgot-password h1{
    margin-bottom: 30px;
    font-size:28px;
}

.content-forgot-password img{
    width: 200px;
    margin-bottom: 5px;
    border-radius: 50%;
}

#loading{
    width: 200px;
}

.content-forgot-password p{
    width: 95%;
    font-size:20px;
    text-align: center;
    margin-bottom: 20px;
}

.content-forgot-password form{
    display:flex;
    flex-flow: column nowrap;
    width: 100%;
    justify-content:center;
    align-items: center;
}
    .content-forgot-password #label{
        margin-bottom: 5px;
        font-size:25px;
    }

    ::placeholder{
        text-align: center;
        color:rgba(0, 0, 0, 0.438);
    }

    :focus::-webkit-input-placeholder {
        opacity: 0;
        transform: translate(70%);
        -webkit-transition: all 0.65s ease-in-out;
        transition: all 0.90s ease-in-out;
      }


    .content-forgot-password #email{
        width: 350px;
        margin-bottom: 20px;
        border:none;
        border-radius: 5px;
        background:rgb(235, 235, 235);
        padding:11px;
        font-size:19px;
        outline: none;
    }

    .content-forgot-password #email:focus{
        box-shadow: 0 0 5px #7c272e; 
        outline: none;
        border: 1px solid #7c272e; 
    }

    .content-forgot-password #password{
        width: 350px;
        margin-bottom: 40px;
        border:none;
        border-radius: 5px;
        background:rgb(235, 235, 235);
        padding:11px;
        font-size:19px;
    }

    .content-forgot-password #send{
        width: 350px;
        margin-bottom: 15px;
        border:none;
        border-radius: 5px;
        background:#7c272e; 
        padding:8px;
        font-size:26px;
        color:white;
        outline: none;
    }

    .content-forgot-password #send:hover{
        background:#ac3741; 
        color:rgb(219, 219, 219);
        cursor: pointer;
    }



.footer{
    display:flex;
    flex-flow: row nowrap;
    background:#912c35; 
    width: 100%;
    
}

    .footer i{
        text-align: center;
        color:white; 
        font-size: 25px;
        padding: 10px;
    }

    .footer i:hover{
        color:#b9b9b9;
    }
    
    .footer a{
    text-align: center;
    color:white;
    font-size: 25px;
    width: 100%;
    padding: 10px;
    font-family: arial;
    }

    .footer a:hover{
        color:#b9b9b9;
    }

    .footer:hover{
        background:#a1343e; 
    }

    

    



    @media (max-width:1050px){
        .content-forgot-password{
          
            width: 40%;
            margin-left:30%;
            margin-right:30%;
            
        }
    }
    
    @media (max-width:950px){
        .content-forgot-password{
          
            width: 45%;
            margin-left:27.5%;
            margin-right:27.5%;
            
        }
    }
    
    @media (max-width:828px){
        .content-forgot-password{
          
            width: 50%;
            margin-left:25%;
            margin-right:25%;
            
        }
    }
    
    @media (max-width:732px){
        .content-forgot-password{
          
            width: 55%;
            margin-left:22.5%;
            margin-right:22.5%;
            
        }
    }
    
    @media (max-width:677px){
        .content-forgot-password{
          
            width: 60%;
            margin-left:20%;
            margin-right:20%;
            
        }
    }
    
    @media (max-width:612px){
        .content-forgot-password{
          
            width: 65%;
            margin-left:17.5%;
            margin-right:17.5%;
            
        }
    }
    
    @media (max-width:559px){
        .content-forgot-password{
          
            width: 70%;
            margin-left:15%;
            margin-right:15%;
            
        }
    }
    
    @media (max-width:520px){
        .content-forgot-password{
            
            width: 75%;
            margin-left:12.5%;
            margin-right:12.5%;
            
        }
    }
    
    @media (max-width:485px){
        .content-forgot-password{
            
            width: 80%;
            margin-left:10%;
            margin-right:10%;
            
        }
    }
    
    @media (max-width:456px){
        .content-forgot-password{
            
            width: 85%;
            margin-left:7.5%;
            margin-right:7.5%;
            
        }
    }
    
    @media (max-width:432px){
        .content-forgot-password{
            
            padding-top:25px;
            width: 90%;
            margin-left:5%;
            margin-right:5%;
            
        }
    }
    
    @media (max-width:405px){
        .content-forgot-password{
            
            width: 95%;
            margin-left:2.5%;
            margin-right:2.5;
            
        }
    }
    
    @media (max-width:380px){
        .content-forgot-password{
          
            width: 100%;
            margin-left:0%;
            margin-right:0%;
            
        }
    }


