.contenido-clientes{
    display:flex;
    flex-flow: column nowrap;
    justify-content:center;
    align-items: center;
    width: 100%;
    margin-top:30px;
}

.contenido-clientes-titulo{
    width: 80%;
}

.contenido-clientes-titulo h1{
    text-align: center;
    font-size: 27px;    
    color:#7c272e; 
    margin-bottom:15px;
    
}

.contenido-clientes-detalles{
   width: 86%;
}

.contenido-clientes-detalles h2{
    
    text-align: justify;
    font-weight: normal;
    font-size: 20px;    
    color:black; 
    margin-bottom:30px;

}

.contenido-clientes-logos{
    width:100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    margin-top: 0px;
    margin-bottom: 50px;
}


.logos img{
    width: 380px;
    padding:5px;
    margin-left: 5px;
    margin-right: 5px;
}

.logos img:hover{
    -webkit-transform: scale(1.11, 1.11);
    -webkit-transition-timing-funcion: ease-out;
    -webkit-transition-duration: 250ms;
}

.contenedor-clientes-precarga{
    margin-top:-5px;
    margin-bottom:-20px;
    width: 90%;
}

@media (max-width:810px) {
    .contenido-clientes-logos{
        flex-flow: column wrap;
    }

    
}



@media(max-width:760px){
    #clientes-p{
        font-size: 14.8px;
    }
}


@media(max-width:600px){
    .logos img{
        width: 350px;
    }
    
    #chedraui{
        width: 200px;
    }
}

@media(max-width:400px){
    .logos img{
        width: 300px;
    }
    
    #chedraui{
        width: 150px;
    }
}