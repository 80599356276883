* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Open Sans', sans-serif;
}

.contenedor-app{
    overflow: hidden;
}

#root{
    overflow: hidden;
}

::placeholder {
    text-align: center;
    color: rgba(0, 0, 0, 0.438);
}

:focus::-webkit-input-placeholder {
    opacity: 0;
    transform: translate(70%);
    -webkit-transition: all 0.65s ease-in-out;
    transition: all 0.90s ease-in-out;
}

input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

@media (max-width: 800px) {
    .contenedor-app {
        margin-top: 69px;
    }
}