.fondo-profile{ 
    display:flex;
    flex-flow: row nowrap;
    width: 100%;
}

.profile-content{
    width: 100%;
    display:flex;
    flex-flow: column nowrap;
    
}

    hr{
        margin-left: 10%;
        width:80%;
    }

    .profile{
      
        background:rgb(34, 34, 34);
    }

        .cover_page{
            overflow: hidden;
            width: 90%;
            height: 280px;
            display:flex;
            margin-left:5%;
            margin-right:5%;
            flex-flow: column nowrap;
            justify-content:center;
            align-items:center;
            border-bottom-left-radius: 14px;
            border-bottom-right-radius: 14px;
        }

        .cover_page img{
            width: 100%;
        }

        .profile_picture{
            padding-top:40px;
            padding-bottom:10px;
            display:flex;
            flex-flow: column nowrap;
            justify-content:center;
            align-items: center;
        }

        .profile_picture img{
            z-index: 1;
            width:180px;
            height:180px;
            z-index: 1;
            margin-top:-145px;
            -moz-border-radius: 50%;
            -webkit-border-radius: 50%;
            border-radius: 50%;
            border:4px solid rgb(34, 34, 34); 
        }

        .profile_picture img:hover{
            filter: blur(1px);
            cursor: pointer;
        }
        
        .username{
            display:flex;
            flex-flow: column nowrap;
            justify-content:center;
            align-items:center;
            text-align: center;
            padding-bottom: 10px;
            
        }

        .username h1{
            
            color:white;
        }

        .username h4{
            color:rgb(89, 89, 253);
        }

        .username h4:hover{
            color:red;
            cursor: pointer;
        }

.main_profile_content{
    
    background:rgb(25, 25, 25);
    display:flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content:space-around;
    padding-top: 20px;
    padding-bottom:120px;
}
    .user_data{
        display:flex;
        flex-flow: column nowrap;
        justify-content:center;
        background:rgb(34, 34, 34);
        border-radius:10px;
        margin-right: 2%;
        padding:20px;
        width: 35%;
        min-height: 280px;
        height: 100%;
    }

    .user_data > form{
        display:flex;
        flex-flow: column nowrap;
        justify-content:center;
        align-items:center;
        text-align: center;
    }

    .user_data > #nPass{
       width: 100%;
       padding:9px;
       font-size: 16px;
       font-weight: bold;
        text-align: center;
        border-radius:7px;
        outline:none;
        border:none;
        color:white;
        background:rgb(61, 61, 61);
    }

    .user_data > #nPass:hover{
         cursor: pointer;
         background:rgb(78, 78, 78);
     }


    .user_data > form > #input_nContrasena{
        width: 100%;
        padding:9px;
        text-align: center;
        border-radius:7px;
        outline:none;
        border:none;
        font-size: 18px;
     }

     .content-btn-nC{
         margin-top:10px;
         display:flex;
         width: 100%;
         justify-content:space-around;
         align-items:center;
         flex-flow: row nowrap;
     }

     .content-btn-nC > #g_nueva_c{
        width: 44%;
        padding:9px;
        text-align: center;
        border-radius:7px;
        outline:none;
        border:none;
        font-size: 18px;
        background:#0071B8;
        color:white;
     }

     .content-btn-nC > #c_nueva_c{
        width: 44%;
        padding:9px;
        text-align: center;
        border-radius:7px;
        outline:none;
        border:none;
        font-size: 18px;
        background:#dd4b39;
        color:white;
     }

     .content-btn-nC > #g_nueva_c:hover{
        cursor: pointer;
        background:rgb(122, 109, 238);
        
     }

     .content-btn-nC > #c_nueva_c:hover{
       cursor: pointer;
       background:#ff6c59;
    
     }
     

    #detalles-perfil{
        color:white;
        font-size: 27px;
        font-weight:bold;
    }

    #fecha_corte{
        color:white;
        font-size: 22px;
        font-weight:normal;
    }

    .content-data-profile{
        display:flex;
        justify-content:flex-start;
        align-items:center;
        flex-flow:row nowrap;
        width: 100%;
        height: 40px;
    }

    #blanco{
        color:white;
        font-size: 18px;
        
    }

    .content-data-profile > h5{
        margin-left: 15px;
        font-size: 18px;
        color:white;
        font-weight: normal;
    }

    .content-borrowed_books{
        
        display: flex;
        justify-content:center;
        align-items: center;
        flex-flow: column nowrap;
        width: 45%;
    }

    .borrowed_books{
        background:rgb(34, 34, 34);
        border-radius:10px;
        padding:20px;
        width: 100%;
        height: 180px;
        display:flex;
        flex-flow:column nowrap;
        align-items:center;
    }

    #ventas_mes{
        margin-bottom: 20px;
    }

    .borrowed_books h2, h3{
        
    }



@media (max-width:910px){
    .cover_page{
        height: 260px;
    }

    .username h1{
            
        font-size:27px;
    }
    .username h4{
        font-size:15px;
    }

    #detalles-perfil{
        margin-bottom:-15px;
    }
}



@media (max-width:800px){
    .cover_page{
        height: 190px;
    }

    .main_profile_content{
        flex-flow: column nowrap;
        align-items:center;
        justify-content:space-between;
    }

    .user_data{
        margin-right: 0px;
        width: 70%;
        overflow: hidden;
    }


    .user_data h3{
        font-size: 24px;
    }
    
   

    .user_data h2{
        font-size: 19px;
    }

    .user_data h2 i{
        
        font-size: 20px;
       
    }

    .content-borrowed_books{

        width: 70%;
    }

    .borrowed_books{
        width: 100%;
        margin-top:20px;
        overflow: hidden;
    }

    #ventas_mes{
        margin-bottom: 3px;
    }
    
}

@media (max-width:550px){
    .cover_page{
        height: 170px;
    }

    .profile_picture img{
        width:130px;
        height:130px;
        margin-top:-125px;
        border:2px solid rgb(34, 34, 34); 
    }

    .username h1{
            
        font-size:24px;
        margin-bottom:5px;
    }
    .username h4{
        font-size:12px;
    }

    .user_data {
        width: 80%;
    }

    .content-borrowed_books {
        width: 80%;
    }


    .user_data h3{
        font-size: 19px;
    }

    .user_data h2{
        font-size: 16px;
    }

    .user_data h2 i{
        
        font-size: 17px;
       
    }

    #detalles-perfil{
        color:white;
        font-size: 23px;
        font-weight:bold;
    }

    #blanco{
        font-size: 16px;
        
    }

    .content-data-profile > h5{
        margin-left: 15px;
        font-size: 16px;
       
    }
}

@media (max-width:410px){
    .cover_page{
        height: 150px;
    }

    .username{
        margin-top:-15px;
    }

    .username h1{
            
        font-size:21px;
    }
    .username h4{
        font-size:10px;
    }

    .user_data h3{
        font-size: 18px;
    }

    .user_data h2{
        font-size: 13px;
    }

    .user_data h2 i{
        
        font-size: 14px;
       
    }

    .profile_picture img{
        width:115px;
        height:115px;
        margin-top:-140px;
    }
}

@media (max-width:380px){
    .profile_picture img{
        width:90px;
        height:90px;
        margin-top:-165px;
    }

    .username{
        margin-top:-35px;
    }

    .username h1{
            
        font-size:16px;
    }

    .user_data{
       
        width: 100%;
    }

    .user_data{
        border-radius: 0px;
    }

    .content-borrowed_books{
        display: none;
    }
   
}