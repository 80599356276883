.contenedor-principal-navbar {
    width: 100%;
    height: 60px;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.274);
    
}

.contenedor-navbar-menu {
    display: none;
}

.contenedor-navbar-accesos-directos {
    width: 23%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    text-align: center;
}

.contenedor-navbar-accesos-directos h2 {
    font-size: 20px;
    font-weight: normal;
}

.contenedor-navbar-accesos-directos i {
    padding-left: 30px;
    font-size: 30px;
}

.contenedor-navbar-logo {
    width: 27%;
    display: none;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    text-align: center;
    overflow: hidden;
}

.contenedor-navbar-logo img {
    padding: 10px;
    width: 130px;
}

.contenedor-navbar-foto-perfil {
    width: 20%;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    overflow: hidden;
    margin-left: 50px;
    padding-top: 2px;
}

.contenedor-navbar-foto-perfil ul li>img {
    border-radius: 50%;
    border: 1px solid rgba(0, 0, 0, 0.24);
    padding: 8px;
    width: 55px;
}

.contenedor-navbar-foto-perfil ul li>img:hover {
    cursor: pointer;
    border: 1px solid #7c272ea8;
}

ul, ol {
    list-style: none;
}

.nav>li {
    float: left;
}

.nav li a {
    background-color: #000;
    color: #fff;
    text-decoration: none;
    padding: 10px 12px;
    display: block;
}

.nav li a:hover {
    background-color: #434343;
}

.nav li ul {
    display: none;
    position: absolute;
    min-width: 140px;
}

.nav li:hover>ul {
    display: block;
}

.nav li ul li {
    position: relative;
}

.nav li ul li ul {
    right: -140px;
    top: 0px;
}

.contenedor-navbar-datos-perfil {
    width: 300px;
    height: 150px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    margin-left: -120px;
    background: #2f353a;
    margin-top: -3px;
    overflow: hidden;
    border-radius: 7px;
    padding: 10px;
    position: relative;
    z-index: 100;
}

.contenedor-navbar-datos-perfil h4 {
    font-size: 15px;
    color: white;
}

.contenedor-navbar-datos-perfil h5 {
    color: white;
    font-size: 15px;
    color: white;
    font-weight: normal;
    margin-top: 20px;
}

.btn-cerrar-sesion {
    margin-top: 20px;
    padding: 10px;
    width: 280px;
    border-radius: 7px;
    color: white;
}

.btn-cerrar-sesion:hover {
    background: #7c272e;
    cursor: pointer;
}

@media (max-width:1100px) {
    .contenedor-navbar-datos-perfil {
        margin-left: -140px;
    }
}

@media (max-width:950px) {
    .contenedor-navbar-logo {
        display: flex;
    }
    .contenedor-navba-menu-texto {
        display: none;
    }
    .contenedor-navbar-menu {
        display: flex;
    }

   
}

@media (max-width:800px) {
    .contenedor-navbar-accesos-directos i {
        font-size: 35px;
    }
    .contenedor-navbar-datos-perfil {
        width: 270px;
        margin-left: -145px;
    }
    .btn-cerrar-sesion {
        width: 250px;
    }
}

@media (max-width:622px) {
    .contenedor-navbar-datos-perfil {
        width: 270px;
        margin-left: -180px;
    }
}

@media(max-width:600px) {
    .contenedor-navbar-logo img {
        width: 100px;
    }
    .contenedor-navbar-foto-perfil>img {
        width: 50px;
    }
}

@media (max-width:450px) {
    .contenedor-navbar-logo {
        display: none;
    }
}

@media (max-width:422px) {
    .contenedor-navbar-logo {
        width: 100px;
    }
}

@media (max-width:470px) {
    .contenedor-navbar-datos-perfil {
        margin-left: -200px;
    }
}

@media (max-width: 421px) {
    .contenedor-navbar-accesos-directos i {
        font-size: 38px;
    }
    .contenedor-navbar-foto-perfil {
        width: 21%;
    }
    .contenedor-navbar-logo img {
        margin-left: 30px;
    }
}

@media (max-width:410px) {
    .contenedor-navbar-datos-perfil {
        margin-left: -210px;
    }
}

@media (max-width:335px) {
    .contenedor-navbar-logo {
        display: none;
    }
    .contenedor-navbar-foto-perfil {
        margin-right: 30px;
    }
    .contenedor-navbar-datos-perfil {
        margin-left: -180px;
    }
}

@media (max-width:268px) {
    .contenedor-principal-navbar {
        border: 1px solid black;
        width: 100%;
    }
    .contenedor-navbar-accesos-directos {
        width: 50%;
    }
    .contenedor-navbar-foto-perfil {
        width: 30%;
        margin-left: -100px;
    }
}

