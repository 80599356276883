.content-header{
    display:flex;
    flex-flow: row nowrap;
    justify-content:center;
    align-items:center;
    width: 100%;
    background:#7c272e; 
    height: 70px;
    
}


.content-header a{
    color:white;
    font-size:20px;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing:1px;
}

.content-header a:hover{
    color:#dadada;
}