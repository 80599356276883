.prin{
    width: 100%;
    overflow: hidden;
}

.contendor-contacto-titulo{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #7c272e;
    margin-top:18px;
    margin-bottom:30px;
    font-size: 18px;
}

.contenido-contacto{
    width: 100%;
    display:flex;
    flex-flow: row;
    justify-content: space-around;
    align-items:center;
    margin-top:15px;
    margin-bottom:40px; 
}

.contenido-contacto-logo{
    width:460px;
    display: flex;
    flex-flow: column nowrap;
    justify-content:center;
    align-items:center;
    margin-top:-50px;

}

.contenido-contacto-logo img{
    border-radius:50%;
    border:8px solid #7c272e;
    color:#7c272e;  
    width: 230px;
}


.contenido-contacto-logo i{
    color:#7c272e;  
}

.contenido-contacto-direccion{
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content:center;
    align-items:center;
    text-align: center;
    margin-top: 20px;
    border-top:3.5px solid #7c272e; 
}



.contenido-contacto-direccion h2{
    padding-top:20px;
    font-size: 18px;
    font-weight: normal;
    margin-left: 10px;
}

.contenido-contacto-telefono-correo{
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content:center;
    align-items:center;
    text-align: center;
    margin-top: -8px;
}



.contenido-contacto-telefono{
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content:center;
    align-items:center;
    text-align: center;
    margin-top: 20px;
    text-decoration:none;
}

.contenido-contacto-telefono a{
    color:#7c272e; 
    text-decoration:none;
}


.contenido-contacto-correo{
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content:center;
    align-items:center;
    text-align: center;
    margin-top: 8px;
}

.contenido-contacto-correo a{
    color:#7c272e; 
    text-decoration:none;
}

.contenido-contacto-telefono-correo h2{
    font-size: 18px;
    font-weight: normal;
    margin-left: 10px;
}

.contenido-contacto-formulario{
    background:#ecf0f5;
    border-radius: 5px;
}

.contenido-contacto-formulario form{
    margin-top:0px;
    padding:20px;
    width:530px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items:center;
    text-align: center;
}

.contenido-contacto-formulario h4{
    margin-top:13px;
    margin-bottom:8px;
    text-align: center;
    font-weight: bold;
    font-size: 21px;
    color:#7c272e; 
}

.contenido-contacto-formulario form input, textarea{
    width:450px;
    font-size: 18px;
    margin-bottom: 15px;
    text-align: center;
    padding:7px;
    border:none;
    border-bottom:1px solid rgba(0, 0, 0, 0.274);
    border-radius: 3px;
}

.contenedor-botones{
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    text-align: center;
}

#cargando{
    padding-top:15px;
    padding-bottom:15px;
}

.contenedor-contacto-botones{
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}


#submit{
    margin-top:17px;
    background:#285ed4; 
    color:white;
    font-size: 20px;
    width: 170px;
    height: 50px;
}

#submit:hover{
    background:#2d71ee; 
    cursor: pointer;
}

.contenido-contacto-formulario form a{
    color:black;
    text-decoration: none;
}

.contenido-contacto-formulario form h5{
    font-weight: normal;
    font-size: 12px;
}

.contenido-contacto-formulario form h5:hover{
    color:rgb(63, 63, 63);
    cursor: pointer;
}

.contenedor-contacto-colaboradores{
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    align-items: center;
}

.contenedor-contacto-colaboradores-titulo{
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
}

.contenedor-contacto-colaboradores-titulo h2{
    color:#7c272e; 
    margin-bottom: 25px;
}

.contenedor-colaboradores{
    width: 30%;
    margin-bottom: 40px;
}

.contenedor-colaboradores a{
    text-decoration: none;
    color: black;
}

.contenedor-mapa{
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
}

@media (max-width:1041px){
    .contenedor-contacto-botones{
        flex-flow: column nowrap;
    }

    .contenedor-botones {
        width: 100%;
    }

    #submit{
        width: 430px;
    }
}

@media (max-width: 1040px) {
    .contenido-contacto-formulario form{
        width:490px;     
    }
    
}

@media (max-width:987px){
    #submit{
        width: 350px;
    }
}

@media (max-width: 980px) {
    .contenido-contacto-formulario form{
        width:430px;     
    }

    .contenido-contacto-formulario form input, textarea{
        width:370px;
    }
    
}

@media (max-width: 900px) {

    .contenido-contacto-logo{
        width: 50%;
    }

    .contenido-contacto-formulario form{
        width:380px;     
    }

    .contenido-contacto-formulario form input, textarea{
        width:340px;
    }
    
}

@media (max-width: 890px) {
    .contenido-contacto-direccion h2{
        font-size: 15px;
        margin-left: 5px;
    }
}

@media (max-width:860px){
    #submit{
        width: 300px;
    }
}

@media (max-width: 850px) {
    .contenido-contacto-formulario form{
        width:330px;     
    }

    .contenido-contacto-formulario form input, textarea{
        width:300px;
    }
    
}

@media (max-width: 800px) {
    .contendor-contacto-titulo{
        margin-top: 100px;
        margin-bottom:0px;
    }

    .contenido-contacto{
        margin-top:0px;
    }

    .contenido-contacto-formulario{
        margin-top:30px;
    }


}



@media (max-width: 760px) {
    .contenido-contacto{
        margin-top:90px;
        flex-flow: column nowrap;
    }

    .contenido-contacto-logo{
        width: auto;

    }

    .contenido-contacto-telefono-correo{
        margin:-12px;
    }

    .contenido-contacto-correo{
        margin:3px;
    }

    .contenido-contacto-formulario{
        margin-top:35px;
    }


    .contenido-contacto-formulario form{
        width:380px;   
       
    }

    .contenido-contacto-formulario form input, textarea{
        width:340px;
    }

    .contenedor-contacto-colaboradores{
        flex-flow: column nowrap;
    }

    .contenedor-colaboradores{
        text-align: center;
        width: 80%;
    }

    
}

@media (max-width:450px){
    .contenido-contacto-formulario form input, textarea{
        font-size: 20px;
        padding:8px;
    }
}

@media (max-width:385px){
    .contenido-contacto-formulario form{
        width:auto;   
       
    }

    .contenido-contacto-formulario form input, textarea{
        font-size: 16px;
        padding:8px;
    }
}

