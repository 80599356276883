.contenedor-principal-cargos{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-flow:column nowrap;
    justify-content:space-between;
    align-items:space-between;
    padding-top: 30px;
}

    .contenedor-cargos-agregar{
        width: 100%;
        display: flex;
        flex-flow:row nowrap;
        justify-content:flex-start;
        align-items: center;
        padding-left:30px;
        text-align: center;
    }

    .contenedor-cargos-agregar button{
        display: flex;
        align-items: center;
        justify-content:center;
        padding:10px;
        font-size: 15px;
        color:white;
        width: 250px;
        min-height: 50px;
        background:#0071B8;
        border:none;
        border-radius: 5px;
        display: flex;
        flex-flow:row nowrap;
        text-align: center;
        outline:none;
       
    }


    .contenedor-cargos-agregar button i{
        margin-right: 10px;
    }
    
    .contenedor-cargos-agregar button:hover{
        background:rgb(122, 109, 238);
    }

    .contenedor-tabla-cargos {
        display:flex;
        justify-content:center;
        align-items: flex-start;
        width:100%;
        margin-top:30px;
        min-height: 100vh;
        
    }


    .contenedor-tabla-cargos .table{
        width:99%;
        margin-bottom: 40px;
       
    }

    .contenedor-tabla-cargos .table, th, td{
        border-collapse: collapse;
        border:2px solid rgba(133, 133, 133, 0.062);
        text-align: center;    
    }

    .contenedor-tabla-cargos .table th{
        color:white;
        background:#2f353a;
    }

    .tbody td{
        text-transform: none;
    }
    
    .contenedor-tabla-cargos .thead tr th{
        padding:10px;
        border-bottom:1px solid#2f353a;
    }
  
    .contenedor-tabla-cargos .tbody tr:nth-child(even){
        background:#f9f9f9;
    }

    .contenedor-tabla-cargos .tbody tr td{
        padding:12px;
    }

    #editar{
        background:#f39c12;
        padding:6px;
        font-size: 14px;
        border:none;
        width: 50px;
        height: 36px;
        border-radius:4px;
        margin-right: 5px;
    }

    #editar:hover{
        background:#ffc871;
        cursor: pointer;
    }
    
    #editar i{
        color:white;
    }
    
    #remover{
        background:#dd4b39;
        padding:6px;
        font-size: 14px;
        width: 50px;
        height: 36px;
        border:none;
        border-radius:4px;
        margin-left: 5px;
    }
    
    #remover:hover{
        background:#ff6c59;
        cursor: pointer;
    }
    
    #remover i{
        color:white;
    }

@media (max-width:1345px){
    
}

@media (max-width:899px){

    #remover{
        margin-top: 20px;
    }
  
}


@media (max-width:500px){

    .contenedor-cargos-agregar{
        width: 100%;
    }
    .contenedor-cargos-agregar button{
        width: 90%;
    }

    #editar{
        width: 40%;
    }

    #remover{
        width: 40%;
    }

}

@media (max-width:380px){
    .contenedor-cargos-agregar button{
        margin-top:50px;
    }

    .contenedor-tabla-cargos .table{
       margin-top:30px;
       
    }
}