.contenedor-principal-dashboard {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    text-align: center;
    overflow: hidden;
}

.contenido-dashboard-asidebar {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    align-items: center;
    text-align: center;
}

.contenedor-dashboard-navbar {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: flex-end;
    background: #ffffff;
}

.contenedor-dashboard-asidebar {
    width: 20%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: #ffffff;
}

.contenedor-dashboard {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    background: #e4e5e6;
    overflow: auto;
}

.contenedor-dashboard-contenido{
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.contenedor-dashboard-titulo{
    width: 100%;
    font-size: 12px;
    margin-top: 15px;
}

.contenedor-dashboard-items{
    width: 98%;
    margin-left: 1px;
    margin-right: 1px;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content:center;
    margin-top: 20px;
}

    .contenedor-dashboard-item{
        width: 48.5%;
        display: flex;
        flex-flow: column nowrap;
        height: 140px;
        border:none;
        margin-left: 10px;
        margin-bottom: 15px;
        color:white;
    }

    .contenedor-dashboard-tarjeta-contenido{
        display: flex;
        flex-flow: row nowrap;
        height: 140px;
        justify-content: space-between;
        align-items:center;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
    }

    .contenedor-dashboard-tarjeta-titulo-descripcion h3{
        margin-top:0px;
        padding-left: 20px;
        font-size: 35px;
    }

    .contenedor-dashboard-tarjeta-titulo-descripcion h4{
        margin-top:10px;
        padding-left: 20px;
        font-size: 18px;
        font-weight: normal;
        font-family: arial;
    }

    .contenedor-dashboard-tarjeta-imagen{
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-end;
        align-items: flex-end;
        margin-top: -15px;
        width: 120px;
        height: 100px;
        margin-right:30px;
    }

    .contenedor-dashboard-tarjeta-imagen img{
        width: 65px;
    }

    .contenedor-dashboard-tarjeta-imagen img:hover{
        width: 85px;
        transition:0.5s;
    }

    .contenedor-dashboard-tarjeta-ver-mas{
        display: flex;
        flex-flow: row nowrap;
        justify-content:center;
        align-items: center;
        padding:1px;
        height: 50px;
        font-size: 20px;
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
    }

    .contenedor-dashboard-tarjeta-ver-mas a{
        width: 100%;
        font-size: 20px;
        color:white;
    }

    .contenedor-dashboard-tarjeta-ver-mas i{
        margin-left:5px;
        font-size: 17px;
        color:white;
    }

    #azulA{
        background-color:#17a2b8;
    }
    
    #naranjaA{
        background-color: #f39c12;
    }
    
    #rojoA{
        background-color: #dc3545;
    }
    
    #verdeA{
        background-color: #28a745;
    }

    #moradoA{
        background-color: rgb(0, 77, 192);
    }

    #azulB{
        background-color: #1591a5;
    }

    #azulB:hover{
        background-color: #0ba3bb;
        cursor: pointer;
    }
    
    #naranjaB{
        background-color: #da8c10;
    }

    #naranjaB:hover{
        background-color: #d3880f;
        cursor: pointer;
    }
    
    #rojoB{
        background-color: #c6303e;
    }

    #rojoB:hover{
        background-color: #bb2b3a;
        cursor: pointer;
    }

    #moradoB{
        background-color: rgb(31, 104, 214);
    }

    #moradoB:hover{
        background-color: rgb(45, 118, 226);
    }
    
    #verdeB{
        background-color: #24963e;
    }
    
    #verdeB:hover{
        background-color: #29af48;
        cursor: pointer;
    }

.contenedor-redes-sociales{
    width: 98%;
    margin-left:1%;
    margin-right:1%;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
}
    
    .contenedor-redes-sociales-items{
        width: 30%;
        height: 150px;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
        margin-left: 10px;
        padding:60px;
        border-radius: 3px;
        border:none;
    }

    .contenedor-redes-sociales-logos{

    }

    .contenedor-redes-sociales-logos img{
        width: 50px;
    }
    
    .contenedor-redes-sociales-descripcion h3{
        font-size: 30px;
        color:white;
    }

    .contenedor-redes-sociales-descripcion h4{
        color:white;
        font-weight: normal;
    }

    #facebook{
        background:#3b5998;
    }

    #instagram{
        background:#3f729b;
    }



@media (max-width:950px) {
    .contenedor-dashboard-asidebar {
        width: 30%;
    }

    .contenedor-dashboard-asidebar{
        display:none;
    }
}

@media (max-width:884px){

    .contenedor-principal-dashboard{
        overflow: hidden;
    }

    .contenedor-dashboard{
        overflow: auto;
    }

    .contenedor-dashboard-item{
        width: 95%;
        
    }

    .contenedor-dashboard-tarjeta-titulo-descripcion h3{
        font-size: 33px;
    }

    .contenedor-dashboard-tarjeta-titulo-descripcion h4{
        font-size: 17px;
    }

    .contenedor-dashboard-tarjeta-ver-mas {
        height: 50px;
    }

    .contenedor-dashboard-tarjeta-ver-mas a{
        font-size: 25px;
    }

    .contenedor-dashboard-tarjeta-ver-mas i{
        font-size: 19px;
    }

    .contenedor-dashboard-items{
        margin-bottom:60px;
    }
    
}

@media (max-width:700px){
    .contenedor-dashboard-asidebar {
        width: 40%;
    }
}

@media (max-width:680px){
    .contenedor-dashboard-item{
        width: 97.5%;
       
    }
}

@media (max-width:665px){
    .contenedor-redes-sociales-items{
        width: 40%;
    }
}

@media (max-width:500px){
    .contenedor-redes-sociales-items{
        width: 45%;
    }
}

@media (max-width:550px){
    .contenedor-dashboard-asidebar {
        width: 60%;
    }
}

@media (max-width:470px){
    .contenedor-dashboard-item{
        width: 95%;
    }

    .contenedor-dashboard-tarjeta-ver-mas{
        padding:4px;
        font-size: 18px;
    }

    .contenedor-dashboard-tarjeta-titulo-descripcion h3{
        font-size: 30px;
    }

    .contenedor-dashboard-tarjeta-titulo-descripcion h4{
        font-size: 15px;
    }

    .contenedor-redes-sociales-descripcion h3{
        font-size: 26px;
    }

    .contenedor-redes-sociales-descripcion h4{
        font-size: 15px;
    }
}

@media (max-width:410px){
    .contenedor-dashboard-asidebar {
        width: 80%;
    }
}

@media (max-width:342px){
    .contenedor-dashboard-asidebar {
        width: 100%;
    }
}

@media (max-width:332px){
    .contenedor-redes-sociales{
        flex-flow: column nowrap;
    }

    .contenedor-redes-sociales-items{
        width: 96%;
    }

    .contenedor-redes-sociales-items{
        margin-bottom:10px;
    }

}

@media (max-width:320px){
    .contenedor-dashboard-item{
        width: 93%;
    }
}

@media (max-width:285px){

    .contenedor-dashboard-tarjeta-imagen{
        margin-top:-48px;
    }

    .contenedor-dashboard-tarjeta-imagen img{
        width: 50px;
    }

    .contenedor-dashboard-tarjeta-imagen img:hover{
        width: 65px;
    }
}