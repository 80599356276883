.content-footer{
    display:flex;
    flex-flow: row nowrap;
    justify-content:center;
    align-items:center;
    width: 100%;
    background:#7c272e; 
    height: 60px;
    
}


.content-footer a{
    color:white;
    font-size:17px;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing:1px;
}

.content-footer a:hover{
    color:#dadada;
}