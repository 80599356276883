.contenedor-footer {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-around;
    align-items: center;
    background: #7c272e;
    text-align: center;
}

.footer-contenido-pagina {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    align-items: center;
    background: #7c272e;
    text-align: center;
    box-shadow: 0px 0px 12px 4px rgba(0, 0, 0, 0.199);
    padding-top: 20px;
    padding-bottom: 20px;
}

.footer-horarios {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
}

.footer-horarios h2 {
    align-items: center;
    font-size: 17px;
    font-weight: normal;
    color: white;
}

.contenedor-dia-hora {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    color: white;
}

.contenedor-horario {
    width: 120%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 10px;
    padding-bottom: 10px;
}

.footer-idiomas h2 {
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    font-size: 17px;
    font-weight: normal;
    color: white;
}

.footer-redes-sociales {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
}

.footer-redes-sociales h2 {
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    font-size: 17px;
    font-weight: normal;
    color: white;
}

.footer-redes-sociales h3 {
    width: 100%;
    padding-left: 10%;
    padding-right: 10%;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
    font-size: 13px;
    text-align: center;
    font-weight: normal;
    color: white;
}

.footer-redes-sociales i {
    font-size: 40px;
}

.footer-pie-pagina {
    width: 100%;
    height: 70px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    align-items: center;
    background: #72252b;
    text-align: center;
}

.footer-pie-pagina a {
    text-decoration: none;
}

.footer-pie-pagina h1 {
    padding: 10px;
    color: rgb(212, 212, 212);
    font-weight: normal;
    font-size: 14px;
}

.footer-pie-pagina h1:hover {
    color: white;
}

.footer-redes-sociales a i {
    overflow: hidden;
    font-size: 17px;
    background: black;
    padding: 10px;
    border-radius: 50%;
}

.idiomas {
    display: flex;
    flex-flow: row nowrap;
}

.footer-idiomas img {
    width: 55px;
}

.footer-idiomas img:hover {
    -webkit-transform: scale(1.11, 1.11);
    -webkit-transition-timing-funcion: ease-out;
    -webkit-transition-duration: 250ms;
}

.footer-redes-sociales i {
    width: 39px;
    color: rgba(255, 255, 255, 0.678);
}

.footer-redes-sociales i:hover {
    -webkit-transform: scale(1.11, 1.11);
    -webkit-transition-timing-funcion: ease-out;
    -webkit-transition-duration: 250ms;
    color: rgb(255, 255, 255);
}

.footer-pie-pagina-direc {
    width: 100%;
    padding: 12px;
    background: #72252b;
}

.footer-pie-pagina-direc h2 {
    font-size: 14px;
    color: white;
    font-weight: normal;
}

.footer-pie-pagina-direc h2:hover {
    cursor: pointer;
}

@media (max-width:1100px) {
    .footer-contenido-pagina {
        flex-flow: column nowrap;
        justify-content: center;
    }
    .idiomas {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-flow: row;
    }
    .footer-redes-sociales {
        margin-top: 20px;
        margin-bottom: 20px;
    }
}

@media (max-width:700px) {
    .footer-idiomas h2 {
        font-size: 17px;
    }
    .footer-pie-pagina h1 {
        font-size: 17px;
    }
    .footer-idiomas img {
        width: 55px;
    }
    .footer-redes-sociales img {
        width: 40px;
    }
}

@media (max-width:500px) {
    .footer-pie-pagina {
        flex-flow: column nowrap;
        height: auto;
        justify-content: center;
        align-items: center;
    }
    .footer-redes-sociales a i {
        font-size: 19px;
    }
}

@media (max-width:400px) {
    .footer-pie-pagina h1 {
        font-size: 16px;
    }
}